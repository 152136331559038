@media screen and (max-width: 1360px) {
    .content__options__fullcam h1 {
        font-size: 30px;
    }
}

@media screen and (max-width: 1250px) {
    .content__options__fullcam h1 {
        font-size: 30px;
        text-align: center;
    }
}

@media screen and (max-width: 1000px) {
    .content__options__fullcam ul li {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 560px) {
    .content__options__fullcam {
        padding: 40px 30px;
    }

    .content__options__fullcam h1 {
        font-size: 25px;
        line-height: 30px;
    }
}

@media screen and (max-width: 450px) {
    .text__list__options__fullcam { 
        flex: 0;
    }
}