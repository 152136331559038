.info__options__fullcam {
    height: auto;
    background-size: cover;
    background-attachment: fixed;
    background-image: url("../../../images/toolsfullcam/rua_option.jpg");
}

.content__options__fullcam {
    display: flex;
    align-items: center;
    padding: 100px 50px;
    flex-direction: column;
    justify-content: center;
}

.content__options__fullcam h1 {
    color: white;
    font-size: 45px;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
}

.content__options__fullcam ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 60px;
    flex-direction: row;
    justify-content: space-around;
}

.content__options__fullcam ul li {
    width: 300px;
    height: 250px;
    display: flex;
    margin: 0 10px;
    line-height: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.icon__list__options__fullcam {
    width: 120px;
    height: 120px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
}

.text__list__options__fullcam {
    flex: 1;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
    font-family: "Lato", sans-serif;
}
